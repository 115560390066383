<template>
  <div class="business">
    <div class="row justify-content-between col-gap-40">
      <div class="col-md-5">
        <h1>
          Details about <br />
          your business
        </h1>
        <b-form
          @submit.stop.prevent="updateBusinessDetails"
          enctype="multipart/form-data"
        >
          <div class="business__detail">
            <div class="business__detail--logo">
              <base-profile-upload
                name="Image"
                rules="ext:jpg,png"
                v-model="image"
              />
              <p class="subTitle">Add the logo of your business</p>
            </div>
            <div class="business__detail--location" v-b-modal.locationModal>
              <div></div>
              <p class="subTitle">Add the location of <br />your business</p>
            </div>
          </div>
          <div class="button-row">
            <button
              type="submit"
              class="btn btn-primary large"
              :class="processing ? 'processing' : ''"
            >
              Continue
            </button>
          </div>
        </b-form>
        <p class="subTitle">
          By continuing you accept our
          <span>Terms and Conditions and Privacy Policy </span>
        </p>
      </div>
      <div class="col-md-7">
        <div class="image">
          <img
            src="@/assets/images/profile-img.png"
            class="img-fluid"
            alt="profile-img"
          />
        </div>
      </div>
    </div>
    <!-- Location Modal -->
    <b-modal
      id="locationModal"
      centered
      no-close-on-backdrop
      ref="locationModal"
      content-class="accountModal"
    >
      <div class="title">Business Location</div>
      <base-locations v-model="location"></base-locations>
    </b-modal>
  </div>
</template>

<script>
import { UPLOAD_IMAGE, UPDATE_USER } from "@/graphql/common/mutations";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  data() {
    return {
      location: [],
      idLocation: 0,
      image: null,
      processing: false,
    };
  },
  watch: {
    location() {
      this.idLocation = parseInt(this.location.id);
      this.$refs["locationModal"].hide();
    },
  },
  methods: {
    async updateBusinessDetails() {
      this.processing = true;
      await Promise.all([
        this.updateLocation(),
        this.updateProfileImage(),
      ]).then(() => {
        this.processing = false;
        this.$router.push("contact");
      });
    },
    async updateProfileImage() {
      if (this.image != null) {
        await this.$apollo
          .mutate({
            mutation: UPLOAD_IMAGE,
            variables: {
              image: this.image,
            },
          })
          .then((data) => {
            if (data.data.uploadProfile.value != "") {
              this.notify("success", MESSAGES.BUSINESS_LOGO_UPDATED);
            }
          });
      }
    },
    async updateLocation() {
      if (this.idLocation != 0) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              idLocation: this.idLocation,
            },
          })
          .then((data) => {
            if (data) {
              if (data.data.updateUser.state == "success") {
                this.notify("success", MESSAGES.LOCATOIN_UPDATE);
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginRegister {
  .contentWrapper {
    padding-top: 0 !important;
  }
  .business {
    &__detail {
      display: flex;
      align-items: flex-start;
      margin-top: rem(25px);
      &--logo {
        flex: 0 0 150px;
        // max-width: 150px;
        margin-right: rem(20px);
        ::v-deep {
          .profilePhoto {
            // width: 150px;
            // height: 150px;
            // &::before {
            //     width: 60px !important;
            //     height: 76px !important;
            //     background-image: url("../../../assets/images/icons/location.png") !important;
            //     background-repeat: no-repeat;
            // }
            .custom-file {
              width: 150px;
              height: 150px;
              border-radius: 18px;
              background: #e8e8f0;
              &::before {
                width: 79px;
                height: 83px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../../../assets/images/icons/house.png");
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
      &--location {
        & > div {
          position: relative;
          width: 150px;
          height: 150px;
          border-radius: 18px;
          background: #e8e8f0;
          &:hover {
            border: solid 2px var(--primary);
          }
          &::before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            content: "";
            width: 60px !important;
            height: 76px !important;
            background-image: url("../../../assets/images/icons/location.png") !important;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .subTitle {
      margin-top: rem(16px);
      margin-bottom: 6px;
      font-size: rem(16px);
      span {
        display: block;
        font-weight: 700;
      }
    }
    @media screen and (max-width: 1700px) {
      .image {
        img {
          max-width: 540px;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      .image {
        img {
          max-width: 475px;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .image {
        img {
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .button-row {
        justify-content: start;
      }
    }
  }
}
</style>
